const messagesPT: Record<string, string> = {
    accepted: ':attribute deve ser aceito.',
    after: ':attribute deve ser uma data após :date.',
    after_or_equal: ':attribute deve ser uma data posterior ou igual a :date.',
    alpha: ':attribute só pode conter letras.',
    alpha_space: ':attribute só pode conter letras e espaços.',
    alpha_num: ':attribute só pode conter letras e números.',
    alpha_num_space: ':attribute só pode conter letras, números e espaços.',
    alpha_num_dash: ':attribute só pode conter letras, números e traços.',
    alpha_num_dash_space: ':attribute só pode conter letras, números, traços e espaços.',
    array: ':attribute deve ser um array.',
    before: ':attribute deve ser uma data anterior a :date.',
    before_or_equal: ':attribute deve ser uma data anterior ou igual a :date.',
    between: ':attribute deve estar entre :min e :max:type.',
    boolean: ':attribute deve ser verdadeiro ou falso.',
    card_exp: ':attribute deve ser uma data de expiração válida.',
    card_num: ':attribute deve ser um número de cartão de crédito válido.',
    currency: ':attribute deve ser uma moeda válida.',
    date: ':attribute deve ser uma data válida.',
    date_equals: ':attribute deve ser uma data igual a :date.',
    email: ':attribute deve ser um email válido.',
    in: ':attribute deve ser um dos seguintes valores: :values.',
    integer: ':attribute deve ser um número inteiro.',
    max: ':attribute não pode ser maior que :max:type.',
    min: ':attribute deve ser pelo menos :min:type.',
    not_in: ':attribute não pode ser um dos seguintes valores: :values.',
    not_regex: ':attribute não pode corresponder ao formato especificado.',
    numeric: ':attribute deve ser um número.',
    phone: ':attribute deve ser um número de telefone válido.',
    regex: ':attribute deve corresponder ao formato especificado.',
    required: ':attribute é obrigatório.',
    size: ':attribute deve ter um tamanho de :size:type.',
    string: ':attribute deve ser uma string.',
    typeof: ':attribute deve ser do tipo :type.',
    url: ':attribute deve ser uma URL válida.',
};

export default messagesPT;
