import pImg1 from '../images/portfolio/portfolio_item_image_1.png'
import pImg2 from '../images/portfolio/portfolio_item_image_2.png'
import pImg3 from '../images/portfolio/portfolio_item_image_3.png'


import pImg7 from '../images/case/case_image_4.png'
import pImg4 from '../images/case/case_image_1.svg'
import pImg5 from '../images/case/case_image_2.svg'
import pImg6 from '../images/case/case_image_3.png'

// import pImg8 from '../images/portfolio/portfolio_item_image_5.png'
// import pImg9 from '../images/portfolio/portfolio_item_image_6.png'
// import pImg10 from '../images/portfolio/portfolio_item_image_7.png'
// import pImg11 from '../images/portfolio/portfolio_item_image_8.png'
// import pImg12 from '../images/portfolio/portfolio_item_image_9.png'
// import pImg13 from '../images/portfolio/portfolio_item_image_10.png'
// import pImg14 from '../images/portfolio/portfolio_item_image_11.png'


// import tImg1 from '../images/case/icon_angular.svg'
// import tImg2 from '../images/case/icon_elephent.svg'
// import tImg3 from '../images/case/icon_netcore.svg'



const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '2',
        pImg: pImg2,
        title: 'TOEFL Coaching',
        slug: 'TOEFL-Coaching',
        sub: '',
        description: 'Our experienced coaches are not just educators; they are partners in your journey..',
    },
    {
        Id: '3',
        pImg: pImg3,
        title: 'Dashboard Design',
        slug: 'Dashboard-Design',
        sub: 'Web Design',
        description: 'The contents of the citizenship test typically cover a range of subjects ...',
    },
    {
        Id: '4',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '5',
        pImg: pImg2,
        title: 'TOEFL Coaching',
        slug: 'TOEFL-Coaching',
        sub: '',
        description: 'Our experienced coaches are not just educators; they are partners in your journey..',
    },
    {
        Id: '6',
        pImg: pImg3,
        title: 'Dashboard Design',
        slug: 'Dashboard-Design',
        sub: 'Web Design',
        description: 'The contents of the citizenship test typically cover a range of subjects ...',
    },
    {
        Id: '7',
        pImg: pImg7,
        title: 'Mauricio de Souza Produções',
        slug: 'https://turmadamonica.uol.com.br/',
        sub: 'Entretenimento',
        Industry: 'Entretenimento',
        Country: 'Brasil',
        Modulo1: 'Avaliação de competências',
        slug1: '',
        Modulo2: '',
        slug2: '',
        description: '"A Mauricio de Sousa Produções é uma das maiores empresas de entretenimento do Brasil, responsável por uma das marcas mais admiradas do país, a Turma da Mônica. A MSP investe em inovação e produz conteúdos em todas as plataformas com a mais alta tecnologia, alinhando educação, cultura e entretenimento.".',
    },
    {
        Id: '8',
        pImg: pImg4,
        title: 'Kompenson',
        slug: 'https://kompenson.com.br/',
        sub: 'Serviços consultivos',
        Industry: 'Serviços',
        Country: 'Brasil',
        Modulo1: 'Descrição de cargos',
        slug1: 'descricao-cargos',
        Modulo2: '',
        slug2: '',
        description: 'Remuneração, Pessoas & Organizações. Somos uma consultoria expert em soluções corporativas inovadoras, que une a vasta experiência dos consultores, com o olhar sempre antenado nas novas tendências. Há mais de 20 anos, ajudamos as empresas a alcançarem a melhor proposta de valor para o seu momento organizacional, desenvolvendo modelos organizacionais independentes e implementados com estratégias flexíveis. Isso significa que são modelos organizacionais únicos e que evoluem junto com o ciclo de vida da sua empresa. Nossas soluções são personalizadas e permeiam todas as abordagens de remuneração, governança e gestão de pessoas.',
    },
    {
        Id: '9',
        pImg: pImg6,
        title: 'Care Global',
        slug: 'https://careglobalpartners.com.br/?gad_source=1&gclid=Cj0KCQjwpP63BhDYARIsAOQkATY348ILj_mmoH6ovWhgmaLzLlCpGdspzqZ7o8UOv_O_ul78ZtybQzYaAo36EALw_wcB',
        sub: 'Saúde',
        Industry: 'Saúde',
        Country: 'Brasil',
        Modulo1: 'Avaliação de competências',
        slug1: '',
        Modulo2: '',
        slug2: '',
        description: '"Resolvemos desafios emocionais e promovemos a prosperidade dos negócios. A CARE oferece o Programa de Apoio ao Empregado e soluções de gestão de saúde mental para mais de 580 grupos empresariais. São mais de 1,8 milhão de pessoas, sejam funcionários ou dependentes cobertos pelo programa que são atendidos por uma rede credenciada de mais de 2.200 profissionais.".',
    },
    {
        Id: '10',
        pImg: pImg5,
        title: 'GTR3S',
        slug: 'https://gtr3s.com.br/',
        sub: 'Serviços consultivos',
        Industry: 'Serviços',
        Country: 'Brasil',
        Modulo1: 'Descrição de cargos',
        slug1: 'descricao-cargos',
        Modulo2: '',
        slug2: '',
        description: 'Nossa combinação de expertises nos permite atender às empresas de forma holística, compreendendo e trabalhando com os três Gs – Gente, Gestão e Governança – que consideramos serem os pilares para levar qualquer organização em direção ao sucesso. Trilhamos a jornada de cada um de nossos clientes em conjunto, considerando as melhores práticas de mercado, passando por diversas áreas de consultoria indicadas em nossa árvore, que permitem às empresas fortalecerem suas raízes, crescerem e colherem os frutos de uma gestão e governança competentes.',
    },
   
   
    // {
    //     Id: '10',
    //     pImg: pImg7,
    //     title: 'Driving Digital Transformation Explore the Depth of Our IT Projects',
    //     slug: 'Driving-Digital-Transformation-Explore-the-Depth-of-Our-IT Projects',
    //     thumb1: 'Logo Design',
    //     thumb2: 'Finance',
    //     category: 'technology',
    // },
    // {
    //     Id: '17',
    //     pImg: pImg8,
    //     title: 'Explore Our IT Solutions Portfolio for Public Sector Organizations',
    //     slug: 'Explore-Our-IT-Solutions',
    //     thumb1: 'App Design',
    //     thumb2: 'Public',
    //     category:'helpdesk'
    // },
    // {
    //     Id: '18',
    //     pImg: pImg9,
    //     title: 'Innovative Solutions Showcase the Diversity of Our IT Portfolio',
    //     slug: 'Innovative-Solutions-Showcase-the-Diversity-of-Our-IT-Portfolio',
    //     thumb1: 'Card Design',
    //     thumb2: 'Transpiration',
    //     category:'analysis'
    // },
    // {
    //     Id: '19',
    //     pImg: pImg10,
    //     title: 'Tech Triumphs Celebrating Our Achievements in IT Solutions',
    //     slug: 'Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions',
    //     thumb1: 'Web Design',
    //     thumb2: 'Logistic',
    //     category: 'technology',
    // },
    // {
    //     Id: '20',
    //     pImg: pImg11,
    //     title: 'Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions',
    //     slug: 'Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions',
    //     thumb1: 'Web Design',
    //     thumb2: 'Fution',
    //     category: 'analysis',
    // },
    // {
    //     Id: '21',
    //     pImg: pImg12,
    //     title: 'Cloud Migration and Integration Project IT Solutions Portfolio',
    //     slug: 'Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio',
    //     thumb1: 'Web Design',
    //     thumb2: 'Energy',
    //     category: 'helpdesk'
    // },
    // {
    //     Id: '22',
    //     pImg: pImg13,
    //     title: 'Pioneering Progress Exploring the Evolution and Impact of',
    //     slug: 'Pioneering-Progress-Exploring-the-Evolution-and-Impact-of',
    //     thumb1: 'Web Design',
    //     thumb2: 'Health',
    //     category: 'marketing'
    // },
    // {
    //     Id: '23',
    //     pImg: pImg14,
    //     title: 'Unlocking Potential Explore Our Comprehensive IT Portfolio',
    //     slug: 'Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio',
    //     thumb1: 'Web Design',
    //     thumb2: 'Industry',
    //     category: 'technology',
    // },

]

export default Project;