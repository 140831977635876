import React from 'react'
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import ContactForm from '../ContactFrom/ContactForm'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_5.svg'
import { Element } from 'react-scroll';


const ContactSection = (props) => {
    return (

        <Element name="contactSection">
            <section className="contact_section pb-80 bg-light section_decoration">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4" >
                            <div className="contact_method_box" style={{ height: '856px', overflowY: 'auto' }}>
                                <div className="heading_block">
                                    <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                        {/* You Are Here */}
                                        <b>Teste gratuitamente</b>
                                    </div>
                                    <h2 className="heading_text mb-0 text-center" style={{ fontSize: '35px' }}>
                                        Vamos começar?
                                    </h2>
                                    <div className='text-center'>
                                        <a target='blank' className="btn" style={{ marginTop: '20px' }} href="https://proleadia.com.br/registration">
                                            <span className="btn_label" style={{ fontSize: '16px' }} data-text="Experimente agora!">Experimente agora!</span>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-arrow-up-right"></i>
                                            </span>
                                        </a>
                                    </div>
                                    {/* <h2 className="heading_text mt-3 text-center" style={{fontSize: '35px', marginBottom: 0}}>Ou</h2>
                                <h2 className="heading_text text-center" style={{fontSize: '35px'}}>entre em contato:</h2> */}
                                    <h2 className="heading_text mt-4 text-center" style={{ fontSize: '25px' }}>ou entre em contato:</h2>
                                </div>
                                <ul className="contact_method_list unordered_list_block">
                                    <li>
                                        <a href="tel:+5511993470056">
                                            <span className="icon">
                                                <i className="fa-solid fa-phone-volume"></i>
                                            </span>
                                            <span className="text">+55 (11) 99347-0056</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:Techco@gmail.com">
                                            <span className="icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </span>
                                            <span className="text" style={{ wordBreak: 'break-all' }}>contato@pro4tech.com.br</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/place/Coworking+Av.+Paulista+302+%7C+Go+Paulista+2+%7C+Gowork/@-23.5691415,-46.6471683,19.25z/data=!4m15!1m8!3m7!1s0x94ce595f87bffe29:0x78f18e7652fdd712!2sAv.+Paulista,+302+-+Bela+Vista,+S%C3%A3o+Paulo+-+SP,+01310-000!3b1!8m2!3d-23.569062!4d-46.6466554!16s%2Fg%2F11y5gr42w_!3m5!1s0x94ce57a86fcea007:0x15331db5cc3ea1c6!8m2!3d-23.5688619!4d-46.6464484!16s%2Fg%2F11gcg27m2x?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D">
                                            <span className="icon">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </span>
                                            <span className="text">
                                                Av. Paulista, N. 302-306, SP <br />
                                                - Edifício José M Borges</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="support_step unordered_list_block">
                                    <li>
                                        <span className="serial_number">01</span>
                                        <span className="text">Compartilhe seus requisitos</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">02</span>
                                        <span className="text">Discuta-os com nossos especialistas</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">03</span>
                                        <span className="text">Faça um orçamento</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">04</span>
                                        <span className="text">Inicie seu projeto</span>
                                    </li>
                                </ul>
                                {/* <a target='blank' className="btn" style={{marginTop: '20px'}} href="https://proleadia.com.br/registration">
                                <span className="btn_label" data-text="Experimente agora!">Experimente agora!</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="instant_contact_form" style={{ height: '856px', overflow: 'auto' }}>
                                <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                    <b>Prefere que entremos em contato?</b>
                                </div>
                                <h3 className="form_title">
                                    Preencha o formulário e descubra como nossa IA pode trazer autonomia total ao RH da sua empresa!
                                </h3>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="decoration_item shape_image_1">
                    <img src={shape1} alt="Techco Shape" />
                </div>
                <div className="decoration_item shape_image_2">
                    <img src={shape2} alt="Techco Shape" />
                </div>
                <div className="decoration_item shape_image_3">
                    <img src={shape3} alt="Techco Shape" />
                </div>
            </section>
        </Element>

    )
}

export default ContactSection;