import React from 'react';
import Services from '../../api/service'
import { Link } from "react-router-dom";
import { Link as Linkscroll } from 'react-scroll';

const ServiceSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="service_section section_space xb-hidden pb-0">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        <mark>Conheça</mark>
                    </div>
                    <h2 className="heading_text mb-0">
                        Nossos módulos
                    </h2>
                </div>

                <div className="row" style={{ paddingBottom: '20px' }}>
                    {Services.slice(0, 2).map((service, srv) => (
                        <div className={`${service.col} mt-30`} key={srv}>
                            {service.title ?
                                <div className="service_block">
                                    <div className="service_image">
                                        <img src={service.sImg} alt="IT Management Services" />
                                    </div>
                                    <div className="service_content">
                                        <h3 className="service_title_2"><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link>
                                        </h3>
                                        <div className="links_wrapper_frase">
                                            <h3 className="service_frase"><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.frase}</Link></h3>
                                            <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="icon_block" style={{alignSelf: 'center'}}>
                                                <i className="fa-regular fa-arrow-up-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    ))}
                </div>

                <div className="btns_group" style={{ paddingBottom: '50px' }}>
                    <a target='blank' className="btn" href="https://proleadia.com.br/registration" style={{ width: '277px' }}>
                        <span className="btn_label" data-text="Experimente Agora">Experimente Agora</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </a>
                    <Linkscroll to="contactSection" smooth={true} duration={500}>
                        <a target='blank' className="btn" href="https://proleadia.com.br/registration" style={{ width: '277px' }}>
                            <span className="btn_label" data-text="Fale Conosco">Fale Conosco</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </a>
                    </Linkscroll>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;