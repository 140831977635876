import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import messagesPT from '../software-company-components/ContactFrom/validator/validatorMessages.ts'

const ContactForm = (props) => {

    const [forms, setForms] = useState({
        Nome: '',
        Sobrenome: '',
        Email: '',
        Empresa: '',
        Celular: '',
        Observacao: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: messagesPT
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            console.log(forms);
            try {
                const response = await fetch('https://localhost:44301/Site/Contato', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(forms)
                });

                if (response.ok) {
                    setForms({
                        Nome: '',
                        Sobrenome: '',
                        Email: '',
                        Empresa: '',
                        Celular: '',
                        Observacao: ''
                    })
                } else {
                    console.log('erro');
                }
            } catch (error) {
                console.log(error);   
            }
            
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-6" >
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">Nome
                        </label>
                        <input
                            id='input_name'
                            value={forms.Nome}
                            type="text"
                            name="name"
                            className="form-control capitalize-first-letter"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nome" />
                        {validator.message('Nome', forms.Nome, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6" >
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_sobrenome">Sobrenome
                        </label>
                        <input
                            id='input_sobrenome'
                            value={forms.Sobrenome}
                            type="text"
                            name="Sobrenome"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Sobrenome" />
                        {validator.message('Sobrenome', forms.Sobrenome, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">Email
                        </label>
                        <input
                            id='input_email'
                            value={forms.Email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Email" />
                        {validator.message('Email', forms.Email, 'required|email')}
                    </div>
                </div>
                <div className="col-6" >
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">Telefone</label>
                        <input
                            id='input_phone'
                            value={forms.Celular}
                            type="phone"
                            name="Celular"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Número de celular" />
                        {validator.message('Celular', forms.Celular, 'required|phone')}
                    </div>
                </div>
                <div className="col-6" >
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_company">Empresa</label>
                        <input
                            id='input_company'
                            value={forms.empresa}
                            type="company"
                            name="empresa"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            // placeholder="Your Company Name" />
                            placeholder="Nome da sua empresa" />
                        {validator.message('Empresa', forms.Empresa, 'required')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">Mensagem</label>
                        <textarea
                            rows={9}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.Observacao}
                            type="text"
                            name="mensagem"
                            className="form-control"
                            placeholder="Como podemos te ajudar?">
                        </textarea>
                        {validator.message('Observacao', forms.Observacao, 'required')}
                    </div>
                    <button type="submit" className="btn btn-primary" style={{ float: 'right' }}>
                        <span className="btn_label" data-text="Enviar">Enviar</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;