import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import messagesPT from './validator/validatorMessages.ts'
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

const ContactForm = (props) => {
    const API_URL = process.env.REACT_APP_API_URL

    const [forms, setForms] = useState({
        Nome: '',
        Sobrenome: '',
        Email: '',
        Empresa: '',
        Celular: '',
        Observacao: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: messagesPT
    }));

    const changeHandler = e => {
        
        if (e.target.name === 'Celular') {
            const rawValue = e.target.value.replace(/\D/g, '');
            console.log(rawValue);
            setForms({ ...forms, [e.target.name]: rawValue })
        } else {
            setForms({ ...forms, [e.target.name]: e.target.value })
        }
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        console.log(validator.allValid());
        console.log(forms);

        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            try {
                const response = await fetch(`${API_URL}/Site/Contato`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(forms)
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log(result);

                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso!',
                        text: 'Sucesso ao enviar o e-mail!'
                    });
                    setForms({
                        Nome: '',
                        Sobrenome: '',
                        Email: '',
                        Empresa: '',
                        Celular: '',
                        Observacao: ''
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro!',
                        text: 'Erro ao enviar o e-mail!'
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro!',
                    text: 'Erro ao enviar o e-mail!'
                });
            }

        } else {
            validator.showMessages();
        }
    };
    return (


        <form className="xb-item--form contact-from" onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-6" style={{ padding: '15px' }}>
                    <div className="form-group" >
                        <label className="input_title" htmlFor="input_name">
                            <i className="fa-regular fa-user"></i>
                        </label>
                        <input
                            value={forms.Nome}
                            type="text"
                            name="Nome"
                            className="form-control capitalize-first-letter"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nome" />
                        {validator.message('Nome', forms.Nome, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6" style={{ padding: '15px' }}>
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">
                            <i className="fa-regular fa-user"></i>
                        </label>
                        <input
                            value={forms.Sobrenome}
                            type="text"
                            name="Sobrenome"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Sobrenome" />
                        {validator.message('Sobrenome', forms.Sobrenome, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-12" style={{ padding: '15px' }}>
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">
                            <i className="fa-regular fa-envelope"></i>
                        </label>
                        <input
                            value={forms.Email}
                            type="email"
                            name="Email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Email" />
                        {validator.message('Email', forms.Email, 'required|email')}
                    </div>
                </div>
                <div className="col-md-6" style={{ padding: '15px' }}>
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">
                            <i className="fa-regular fa-phone-volume"></i>
                        </label>
                        <InputMask
                            mask="(99) 99999-9999"
                            value={forms.Celular}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                        >
                            {() => <input
                                type="tel"
                                name="Celular"
                                className="form-control"
                                placeholder="Número de celular"
                            />}
                        </InputMask>
                        {validator.message('Celular', forms.Celular, 'required|phone')}
                    </div>
                </div>
                <div className="col-md-6" style={{ padding: '15px' }}>
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_company">
                            <i className="fa-regular fa-globe"></i>
                        </label>
                        <input
                            value={forms.Empresa}
                            type="company"
                            name="Empresa"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nome da sua empresa" />
                        {validator.message('Empresa', forms.Empresa, 'required')}
                    </div>
                </div>
                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">
                            <i className="fa-regular fa-comments"></i>
                        </label>
                        <textarea
                            rows={9}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.Observacao}
                            type="text"
                            name="Observacao"
                            className="form-control"
                            placeholder="Como podemos te ajudar?">
                        </textarea>
                        {validator.message('Observação', forms.Observacao, 'required')}
                    </div>
                    <button type="submit" className="btn btn-primary" style={{ float: 'right' }}>
                        <span className="btn_label" data-text="Enviar">Enviar</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;