import React from 'react'
import { Link } from 'react-router-dom'
import Bg from '../../../images/shapes/bg_pattern_3.svg'
import Bg2 from '../../../images/shapes/shape_space_6.svg'
import icon1 from '../../../images/shapes/shape_space_2.svg'
import icon2 from '../../../images/icons/icon_mail_2.svg'
import Slider from "react-slick";
import LogoFooter from '../../../images/site_logo/poweredPro4Tech.png'
import logo from '../../../images/site_logo/site_logo_footer.svg'

const ClickHandler = () => {
  window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
  e.preventDefault()
}

var settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Footer = (props) => {
  return (
    <footer className="site_footer footer_layout_2 section_decoration" style={{ backgroundImage: `url(${Bg})` }}>
      <div className="decoration_item shape_image_1">
        <img src={icon1} alt="Techco Shape" />
      </div>
      <div className="container">
        <h3 className="footer_info_title text-center">Especializações</h3>
        <div className="service_pill_carousel swiper" >
          <Slider {...settings}>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Automação</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Inteligência artificial</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>App Development</span> */}
                <span>Pesquisa de mercado</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>Web Development</span> */}
                <span>Processos de RH</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>Software Solution</span> */}
                <span>Compliance e Segurança</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>Enterprise Apps</span> */}
                <span>Interface Intuitiva</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>Enterprise Apps</span> */}
                <span>Experiência do Usuário</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>Devops Services</span> */}
                <span>Escalabilidade</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                {/* <span>App Development</span> */}
                <span>Eficiência Operacional</span>
              </a>
            </div>
          </Slider>
        </div>
        <div className="footer_main_content">
          <div className="row justify-content-lg-between">
            <div className="col-lg-3 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Empresa</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      <span className="icon_list_text">
                        Nosso propósito
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">
                        Our achievements
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      <span className="icon_list_text">
                        Our partners
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      <span className="icon_list_text">
                        Our locations
                      </span>
                    </Link>
                  </li> */}
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">
                        Soluções
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      <span className="icon_list_text">
                        Contato
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-5 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Especialização</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_text text-white">
                      Automação e inteligência artificial
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Pesquisa de mercado
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Processos de RH
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Compliance e Segurança
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Interface Intuitiva e Experiência do Usuário
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Escalabilidade
                    </span>
                  </li>

                </ul>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Industrias</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_text text-white">
                      Consultorias
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Infraestrutura
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Mobilidade
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Entretenimento
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Energia
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Automação industrial
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text text-white">
                      Saúde
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 footerResponsive" style={{ position: 'relative' }}>
              <div className="footer_widget pe-lg-3">
                <h2 className="footer_info_title footerResponsive" style={{ position: 'absolute', right: 0 }}>Powered by</h2>
                <p className='footerResponsive' style={{ position: 'absolute', right: 0, top: '72px' }}>
                  <img src={LogoFooter} />
                </p>
                <div className='footerResponsive' style={{ position: 'absolute', right: 0, top: '165px' }}>
                  <ul className="social_icons_block unordered_list">
                    <li>
                      <a target="blank" href="https://www.linkedin.com/company/prolead-rh/">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a target="blank" href="https://www.instagram.com/pro4tech.com.br">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="blank" href="https://www.facebook.com/ProLeadRH">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom" style={{ backgroundImage: `url(${Bg2})` }}>
        <div className="container d-md-flex align-items-md-center justify-content-md-between">
          <p className="copyright_text m-0" style={{ color: '#FFF' , width: '40%'}}>
            <img src={logo} style={{ width: '14%'}} /> <b>|</b> Copyright © 2024
          </p>
          <ul className="icon_list unordered_list">
            <li>
              <Link onClick={ClickHandler} to="/contact">
                <span className="icon_list_icon">
                  <i className="fa-solid fa-circle"></i>
                </span>
                <span className="icon_list_text text-white">
                  Termo de uso
                </span>
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/contact">
                <span className="icon_list_icon ">
                  <i className="fa-solid fa-circle" ></i>
                </span>
                <span className="icon_list_text text-white">
                  Políticas de privacidade
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;