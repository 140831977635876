import sImg1 from '../images/services/service_image_1.png'
import sImg2 from '../images/services/service_image_2.png'
import sImg3 from '../images/services/service_image_3.png'
import sImg4 from '../images/services/service_image_4.png'
import sImg5 from '../images/services/service_image_5.png'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'
import icon6 from '../images/icons/icon_programming.svg'

import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'

import icon13 from '../images/icons/icon_avatar_gear.svg'
import icon14 from '../images/icons/icon_timer.svg'
import icon15 from '../images/icons/icon_puzzle.svg'
import icon16 from '../images/icons/icon_chartIA.svg'
import icon17 from '../images/icons/icon_shield.svg'
import icon18 from '../images/icons/icon_rocket.svg'



const Services = [
    {
        Id: '1',
        sImg:sImg1,
        title: 'Descrição de cargos',
        textTitle: 'Transformação Digital em Descrição de Cargos',
        p1: 'O módulo de Descrição de Cargos é uma solução inovadora que transforma a maneira como as empresas gerenciam e estruturam suas funções. Utilizando inteligência artificial, a ferramenta facilita a criação, edição e aprovação de descrições de cargos com maior precisão e agilidade, atendendo às demandas específicas de cada organização. Além disso, promove padronização e alinhamento estratégico entre as áreas de Recursos Humanos e liderança.',
        p2: 'Nosso sistema oferece uma abordagem completa e personalizável para a gestão de descrições de cargos, levando em conta fatores críticos como compliance, evolução dos cargos e mudanças nas necessidades organizacionais. Com o suporte de uma interface intuitiva e flexível, o módulo auxilia na tomada de decisões assertivas, garantindo maior eficiência e clareza nas responsabilidades de cada função.',
        slug: 'descricao-cargos',
        frase:'Surpreenda com o apoio de nossa plataforma para descrever cargos',
        col:'col-lg-6',
        description:'Visit new places to discover with a Tourist Visa. We deliver your documents ...',
    },
    {
        Id: '2',
        sImg:sImg2,
        title: 'Pesquisa',
        textTitle: 'Pesquisas Inteligentes e Customizáveis',
        p1: 'O módulo de Pesquisa oferece uma solução inovadora e flexível, permitindo que as empresas criem pesquisas totalmente personalizadas sobre qualquer tema. Utilizando inteligência artificial, a ferramenta facilita a criação automática de tópicos, dimensões e perguntas, adaptando-se a diferentes contextos e necessidades. Seja para medir clima organizacional, engajamento ou explorar outros assuntos estratégicos, o sistema garante agilidade e precisão na geração de conteúdos.',
        p2: 'Com a possibilidade de personalização completa, os gestores têm o controle sobre os temas abordados e a estrutura das pesquisas, alinhando-as aos objetivos da organização. A interface intuitiva e os insights detalhados ajudam a identificar tendências, pontos de melhoria e oportunidades de desenvolvimento, promovendo uma gestão mais assertiva e alinhada às demandas do negócio.',
        slug: 'pesquisa',
        frase:'Elabore pesquisas com IA e obtenha insights rápidos e precisos',
        col:'col-lg-6',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '3',
        sImg:sImg3,
        title: 'Website Development',
        slug: 'Website-Development',
        thumb1:'Landing Page',
        thumb2:'Plugins',
        col:'col-lg-4',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '4',
        sImg:sImg4,
        title: 'Modern Technology Solution',
        slug: 'Modern-Technology-Solution',
        thumb1:'Consultation',
        thumb2:'solution',
        col:'col-lg-4',
        description:'Embarking on a journey of higher education in a foreign country opens doors to...',
    },
    {
        Id: '5',
        sImg:sImg5,
        title: 'UI/UX Design Services',
        slug: 'UI-UX-Design-Services',
        thumb1:'Website',
        thumb2:'Mobile App',
        col:'col-lg-4',
        description:'Expert Guidance for a Seamless Immigration Journey Expert Guidance...',
    },
    {
        Id: '6',
        sImg:icon1,
        title: 'Custom Software Development',
        slug: 'Custom Software Development',
        features: ['Software architecture design', 'System integration services', 'Data migration services', 'Legacy app modernization']
    },
    {
        Id: '7',
        sImg:icon2,
        title: 'Audit & IT Consulting Services',
        slug: 'Audit-&-IT-Consulting-Services',
        features: ['TechGuard Audit', 'CyberSafe Audit & IT Consulting', 'AssuranceEdge & IT Consulting', 'IT Sentry Audit & IT Consulting']
    },
    {
        Id: '8',
        sImg:icon3,
        title: 'Web Application Design and Development',
        slug: 'Web-Application-Design-and-Development',
        features: ['Web app development services', 'Web portal development services', 'Website development services', 'Offshore web development']
    },
    {
        Id: '9',
        sImg:icon4,
        title: 'Mobile App Design and Development',
        slug: 'Mobile-App-Design-and-Development',
        features: ['Android development services', 'iOS app development services', 'Mobile application design services', 'Enterprise mobile app development']
    },
    {
        Id: '10',
        sImg:icon5,
        title: 'Best UI/UX Design Services',
        slug: 'Best-UI/UX-Design-Services',
        features: ['PixelPerfection UI/UX Design', 'DesignCraft UI/UX Design', 'CreativeWave UI/UX Design', 'InterfaceGenius UI/UX Design']
    },
    {
        Id: '11',
        sImg:icon6,
        title: 'Maintenance and Customer Support',
        slug: 'Maintenance-and-Customer-Support',
        features: ['CareCraft Maintenance', 'FixItPro Maintenance', 'TechCare Maintenance', 'AssistEdge Maintenance']
    },
    {
        Id: '12',
        sImg:icon7,
        title: 'Strategic Planning and Execution',
        slug: 'Strategic-Planning-and-Execution',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '13',
        sImg:icon8,
        title: 'Business Process Optimization',
        slug: 'Business-Process-Optimization',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '14',
        sImg:icon9,
        title: 'Digital Transformation Consulting',
        slug: 'Digital-Transformation-Consulting',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '15',
        sImg:icon10,
        title: 'Strategic Planning and Execution',
        slug: 'Strategic-Planning-and-Executions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '16',
        sImg:icon11,
        title: 'Change Management Solutions',
        slug: 'Change-Management-Solutions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '17',
        sImg:icon12,
        title: 'Performance Metrics and KPI Development',
        slug: 'Performance-Metrics-and-KPI-Development',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '18',
        sImg:icon13,
        title: 'Aumento da autonomia e eficiência operacional',
        slug: 'Aumento da autonomia e eficiência operacional',
        description: 'A IA generativa capacita a área de RH a realizar tarefas complexas de forma autônoma, sem a necessidade de intervenções manuais extensas ou apoio constante de consultorias externas. Isso otimiza processos, reduz tempo e custos, e permite que os profissionais de RH se concentrem em atividades estratégicas.'
    },
    {
        Id: '19',
        sImg:icon14,
        title: 'Agilidade na tomada de decisão',
        slug: 'Agilidade na tomada de decisão',
        description: 'Com a IA gerando análises detalhadas e insights rápidos, as equipes de RH podem tomar decisões informadas em tempo real. Isso é essencial para responder prontamente às necessidades da empresa e se adaptar a mudanças no ambiente organizacional.'
    },
    {
        Id: '20',
        sImg:icon15,
        title: 'Customização e adaptação às necessidades do negócio',
        slug: 'Customização e adaptação às necessidades do negócio',
        description: 'A IA generativa oferece uma abordagem altamente personalizada, permitindo que as soluções sejam ajustadas conforme as demandas específicas da empresa. Desde a criação de documentos a modelos de pesquisa, a IA se adapta facilmente a diferentes contextos, melhorando a aderência e a eficácia das iniciativas de RH.'
    },
    {
        Id: '21',
        sImg:icon16,
        title: 'Melhoria na qualidade das informações',
        slug: 'Melhoria na qualidade das informações',
        description: 'Ao utilizar IA generativa, a qualidade das informações produzidas é significativamente aprimorada. A tecnologia elimina vieses, padroniza conteúdos e garante que todas as saídas sejam consistentes, relevantes e baseadas nas melhores práticas.'
    },
    {
        Id: '22',
        sImg:icon17,
        title: 'Redução de erros e conformidade com normas',
        slug: 'Redução de erros e conformidade com normas',
        description: 'A IA generativa ajuda a reduzir erros humanos e garante que as práticas estejam em conformidade com regulamentos e padrões do setor. Isso é particularmente importante em ambientes de trabalho onde precisão e aderência às normas são essenciais.'
    },
    {
        Id: '23',
        sImg:icon18,
        title: 'Apoio à inovação e transformação digital',
        slug: 'Apoio à inovação e transformação digital',
        description: 'A adoção da IA generativa é um passo estratégico para a transformação digital do RH. Ela promove a inovação contínua, criando uma cultura de dados e insights que coloca o RH como um parceiro estratégico na jornada de crescimento da empresa.'
    },
]    

export default Services;