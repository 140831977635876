
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import TechnologySection from '../TechnologySection/TechnologySection';
import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Conheça
                            </div>
                            <h2 className="heading_text mb-0">
                                Nosso <mark>Fluxo</mark>
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Configurações Iniciais
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                        As configurações iniciais envolvem três etapas principais: a criação do tema da pesquisa, a definição das dimensões e a elaboração das perguntas. Essas etapas são essenciais para garantir que a pesquisa esteja totalmente personalizada e focada nas necessidades da organização. O sistema oferece autonomia completa para criar temas e dimensões de acordo com os objetivos estratégicos e permite a elaboração automática ou manual das perguntas, utilizando inteligência artificial para maior precisão.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                    02. Configuração da Pesquisa
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                        Nesta etapa, a pesquisa é configurada com detalhes essenciais, como a definição da data de início e término e a seleção dos participantes. O sistema garante que a pesquisa seja configurada de forma eficiente, vinculando o tema e as perguntas previamente definidos, além de permitir a personalização dos participantes, sejam eles colaboradores de uma área específica ou da organização como um todo.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Disparar Pesquisa
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                        Com a configuração completa, a pesquisa pode ser disparada para os participantes selecionados. O processo é simples e automático, permitindo que os colaboradores acessem e respondam às perguntas diretamente pelo sistema. O envio das pesquisas é feito de maneira otimizada, assegurando que todos os participantes recebam a pesquisa de forma rápida e eficiente.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Analisar Resultados
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                        Após o término da pesquisa, os resultados podem ser analisados em tempo real por meio de dashboards e relatórios personalizados. O sistema permite o acompanhamento das respostas, proporcionando uma visão clara e objetiva dos dados coletados. As análises são apresentadas de forma visual e detalhada, facilitando a tomada de decisões e identificando tendências e insights importantes para a organização.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Exportar Resultados
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                        Para maior praticidade, os resultados podem ser exportados em diversos formatos, como Excel e PDF. Isso permite a elaboração de relatórios gerenciais, facilitando a apresentação dos dados coletados para diferentes stakeholders. A exportação garante que todos os resultados estejam documentados e prontos para serem compartilhados ou integrados em outros processos.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Configurações Iniciais</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Configuração da Pesquisa</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Disparar Pesquisa</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Analisar Resultados</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Exportar Resultados</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
                {/* <TechnologySection/>
                <Testimonial/> */}
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Techco Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
