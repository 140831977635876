import React from 'react'
import Bg from '../../images/shapes/bg_pattern_4.svg'
import Bgdesc from '../../images/shapes/bg_pattern_5.svg'
import Bgpesquisa from '../../images/shapes/bg_pattern_6.svg'


const PageTitle = (props) => {
    let bannerClass = 'page_banner_section text-center';

    if (props.pageTitle === 'Nosso propósito') {
        bannerClass += ' page_banner_nosso_proposito';
    } else if (props.pageTitle === 'Pesquisa') {
        bannerClass += ' page_banner_section_pesquisa';
    } else {
        bannerClass += ' page_banner_section_desccargo';
    }

    let imagemBG = props.pageTitle === 'Pesquisa' ? Bgpesquisa : props.pageTitle === 'Descrição de cargos' ? Bgdesc : Bg
    
    return (
        <section className={bannerClass} style={{ backgroundImage: `url(${imagemBG})` }}>
            <div className="container">
                <div className="heading_focus_text text-white">
                    {props.pageTop}
                    {/* <span className="badge bg-secondary">{props.pagesub}</span> */}
                </div>
                {props.pageTitle === 'Pesquisa' ? (
                    <h1 className="page_title mb-0 text-white">Obtenha insights profundos para elaborar pesquisas dinâmicas e flexíveis</h1>
                ) : props.pageTitle === 'Descrição de cargos' ? (
                    <h1 className="page_title mb-0 text-white">Autonomia total para criar descrições de cargos personalizadas e assertivas</h1>    
                ) : (
                    <h1 className="page_title mb-0 text-white">{props.pagesub}</h1>
                )}
            </div>
        </section>
    )
}

export default PageTitle;