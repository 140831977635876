import React from 'react';
import Bg from '../../images/backgrounds/bg_image_1.png'
import { Link } from "react-router-dom";

const CtaSection = (props) => {

    return (
        <section className="calltoaction_section parallaxie" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container text-center">
                <div className="heading_block text-white">
                    <h2 className="heading_text">
                        Venha fazer parte!
                    </h2>
                    <p className="heading_description mb-0">
                        Nossa equipe de especialistas está pronta para colaborar com você em todas as etapas do processo, desde a consulta inicial até a implementação.
                    </p>
                </div>
                <a href="https://proleadia.com.br/registration" className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                    <span className="btn_label" data-text="Experimente agora!">Experimente agora!</span>
                    <span className="btn_icon">
                        <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                </a>

            </div>
        </section>
    );
}

export default CtaSection;