
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration" >
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Conheça
                            </div>
                            <h2 className="heading_text mb-0">
                                Nosso <mark>Fluxo</mark>
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Cargos
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                        Oferecemos total flexibilidade para a gestão eficiente de cargos. Os gestores podem optar por cadastrar manualmente as informações de cargos ou importar de forma automatizada dados estruturados, como planilhas de cargos já existentes.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                    02. Personalização de Formulários
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                        A personalização dos formulários de descrição de cargos é completamente flexível, oferecendo autonomia para adaptar cada detalhe às demandas específicas da sua organização. Os gestores podem selecionar campos existentes ou adicionar novos, garantindo que o formulário final esteja alinhado com as necessidades estratégicas do negócio.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Geração Automática de Descrições
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                        Com a IA Generativa, as descrições de cargos são criadas automaticamente de forma precisa e detalhada, baseadas nos modelos personalizados previamente definidos. A tecnologia oferece rapidez e consistência, permitindo que os gestores de RH obtenham descrições alinhadas com as exigências do cargo e da organização. Tudo isso em poucos cliques, otimizando tempo e recursos.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Personalize e Refine as Descrições
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                    Nossos usuários têm a liberdade de revisar e ajustar essas descrições conforme necessário. Se preferir, pode solicitar novas respostas da IA para garantir que o texto atenda exatamente às suas necessidades, ou realizar ajustes manuais diretamente na descrição.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Acervo das Descrições
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                    Os colaboradores têm a opção de submeter as descrições para um workflow de aprovação, garantindo que atendam aos critérios e padrões estabelecidos antes de serem formalmente incorporadas. Alternativamente, é possível salvar diretamente as descrições no acervo do sistema, agilizando a integração das informações no banco de dados.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Cargos</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Personalização de Formulários</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Geração Automática</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Personalize e Refine as Descrições</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Acervo das Descrições</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
                {/* <TechnologySection /> */}
                {/* <Testimonial /> */}
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Techco Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
