import React, { Fragment, useState } from 'react';
import Services from '../../api/service'
import { useParams } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import ProcessTechnologyDescCargo from '../../components/software-company-components/ProcessTechnology/ProcessTechnologyDescCargo';
import ProcessTechnologyPesquisa from '../../components/software-company-components/ProcessTechnology/ProcessTechnologyPesquisa';
import ContactSection from '../../components/software-company-components/ContactSection';
import srImg1 from '../../images/services/service_details_image_1.png'
import srImg2 from '../../images/services/service_details_image_6.png'



const ServiceSinglePage = (props) => {

    const { slug } = useParams()

    const ServiceDetails = Services.find(item => item.slug === slug)

    const [isOpen, setOpen] = useState(false)

    return (
        <Fragment>
            <Header />
            <main className="page_content service-single-page">
                <PageTitle pageTitle={ServiceDetails.title} pagesub={'Autonomia total para criar descrições de cargos personalizadas e assertivas'} pageTop={''} />
                <section className="service_details_section section_space bg-light section_space_ProcessTechnology">
                    <div className="container">
                        <div className="details_item_image_modulos position-relative">
                            {ServiceDetails.title === 'Descrição de cargos' ? (
                                <>
                                    <img src={srImg1} alt="Service Details" />
                                    <button className="video_btn2 ripple_effect" onClick={() => setOpen(true)}>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-play"></i>
                                        </span>
                                    </button>
                                </>
                            ) : ServiceDetails.title === 'Pesquisa' ? (
                                <>
                                    <img src={srImg2} alt="Service Details" />
                                    <button className="video_btn2 ripple_effect" onClick={() => setOpen(true)}>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-play"></i>
                                        </span>
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <h2 className="details_item_title">{ServiceDetails.textTitle}</h2>
                        <p style={{ textAlign: 'justify' }}>
                            {/* {ServiceDetails.title} are the backbone of modern businesses, serving as the foundation upon which all digital operations rely. At our IT solution agency, we specialize in crafting robust and reliable network architectures tailored to meet the unique needs of our clients. From small businesses to large enterprises, we understand the critical importance of a well-designed network infrastructure in driving efficiency, security, and scalability. Our team of experienced professionals works closely with clients. */}
                            {ServiceDetails.p1}
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            {/* We take a holistic approach to network design, considering factors such as bandwidth requirements, security protocols, scalability, and future growth potential. By leveraging industry best practices and cutting-edge technologies, */}
                            {ServiceDetails.p2}
                        </p>
                        {ServiceDetails.title === 'Descrição de cargos' ? (
                            <ProcessTechnologyDescCargo />
                        ) : ServiceDetails.title === 'Pesquisa' ? (
                            <ProcessTechnologyPesquisa />
                        ) : (
                            <></>
                        )}
                    </div>
                </section>
            </main>
            <CtaSection />
            <ContactSection />
            <Footer />
            <Scrollbar />
            {ServiceDetails.title === 'Descrição de cargos' ? (
                <ModalVideo channel='custom' url='https://pro4tech.blob.core.windows.net/visualstuff/Video%20Descricao%20V1.mp4?si=costumer&spr=https&sv=2022-11-02&sr=b&sig=MS11hiwHcDU1kayN0V7S0Y9Z0BgjsPY0jFR%2By7j0WcI%3D' autoplay isOpen={isOpen} onClose={() => setOpen(false)} />
            ) : ServiceDetails.title === 'Pesquisa' ? (
                <ModalVideo channel='custom' url='https://pro4tech.blob.core.windows.net/visualstuff/Video%20Pesquisa%20V1.mp4?si=costumer&spr=https&sv=2022-11-02&sr=b&sig=jzqC3Cb1tOnivsS%2B15j4eA9p2J23a9w2xxezv5glFyo%3D' autoplay isOpen={isOpen} onClose={() => setOpen(false)} />
            ) : (
                <></>
            )}
        </Fragment>
    )
};
export default ServiceSinglePage;