import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Nossa História',
        subTitle: 'Desde 2004, temos sido pioneiros em soluções tecnológicas inovadoras, oferecendo plataformas que capacitam empresas a se destacarem em um mercado em constante evolução. Hoje, somos parceiros confiáveis de marcas de classe mundial, ajudando-as a transformar seus processos e alcançar novos patamares de sucesso.',
        icon: sIcon1,
    },
    {
        title: 'Nossa Missão',
        subTitle: 'Nossa missão é criar soluções inteligentes que empoderam consultorias e empresas, proporcionando autonomia e agilidade na gestão de pessoas. Combinamos tecnologia e inovação para apoiar nossos clientes na superação de desafios operacionais, promovendo a excelência e o crescimento contínuo.',
        icon: sIcon2,
    },
    {
        title: 'Nossa Visão',
        subTitle: 'Nossa visão é ser referência em inovação, utilizando IA para transformar a gestão de talentos e processos organizacionais. Queremos liderar o futuro da tecnologia em RH, ajudando empresas a tornarem-se mais independentes, criativas e estratégicas.',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section bg-light" style={{paddingBottom: '70px'}}>
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem} >
                            <div className="iconbox_block iconbox_block_policy_responsive">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;