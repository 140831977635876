import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Scrollbar = () => {

    const phoneNumber = '5511993470056';
    const message = 'Olá!';

    return (
        <ul className="smothscroll">
            <li>
                <a 
                    href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <i className="fa-brands fa-whatsapp"></i>
                </a>
            </li>
        </ul>
    )
}

export default Scrollbar;
