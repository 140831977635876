import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import aImg from '../../images/about/about_image_3.png'
import aImg2 from '../../images/about/about_image_4.png'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import ContactSection from '../../components/software-company-components/ContactSection';


const AboutUsPage = (props) => {

    const [isOpen, setOpen] = useState(false)
    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Nosso propósito'} pagesub={'Nosso propósito'} pageTop={''} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Techco - About " />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Techco - About" />
                                        <button className="video_btn ripple_effect" onClick={() => setOpen(true)}>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-play"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_block mb-0">
                            <div className="row justify-content-lg-between">
                                {/* <div className="col-lg-4">
                                    <div className="heading_focus_text">
                                        About
                                        <span className="badge bg-secondary text-white">Techco 🙂</span>
                                    </div>
                                    <h2 className="heading_text mb-0">
                                        Nosso propósito
                                    </h2>
                                </div> */}
                                <div className="col-lg-12">
                                    <p className="heading_description mb-6 text-center" style={{ fontSize: "30px" }}><b><mark>Pro4Tech:</mark> Inovação e Autonomia com IA na Gestão de Pessoas e Talentos</b></p>
                                    <p className="heading_description mb-0" style={{ textAlign: 'justify', fontSize: "20px" }}>
                                        Na Pro4Tech, desenvolvemos soluções digitais, ágeis e inovadoras que impulsionam o crescimento das pessoas e criam valor para as empresas. Com a integração da IA generativa, nossas ferramentas evoluíram para oferecer uma experiência inteligente e autônoma, revolucionando a gestão de talentos e a tomada de decisões estratégicas.
                                        Nossas soluções são projetadas tanto para consultorias que buscam eficiência em seus projetos quanto para empresas que desejam operar com mais independência. A tecnologia está profundamente integrada ao dia a dia, melhorando como as pessoas trabalham e vivem, proporcionando uma transformação que vai além do digital, tornando-se parte de cada interação.
                                        Nossa IA não apenas otimiza processos; ela antecipa necessidades, oferece insights e recomendações que capacitam líderes e equipes. O resultado é mais autonomia e inovação em todas as fases dos processos de RH. Isso cria uma experiência única, em que a tecnologia e o talento humano se complementam, elevando a colaboração, alinhando-se aos objetivos estratégicos e promovendo uma verdadeira transformação organizacional.
                                        Essa é mais uma solução de impacto desenvolvida pelo time da Pro4Tech, unindo tecnologia e criatividade para transformar o futuro das empresas!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PolicySection />
                <CtaSection />
                <ContactSection />
            </main>
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default AboutUsPage;
