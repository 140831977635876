import React from 'react';
import Services from '../../../api/service'
import { Link } from 'react-scroll';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_1.svg'
import shape4 from '../../../images/shapes/shape_angle_1.png'
import shape5 from '../../../images/shapes/shape_angle_2.png'


const ServiceSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="service_section pt-175 pb-80 bg-light section_decoration xb-hidden">
            <div className="container">
                <div className="heading_block text-center">
                    {/* <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        Our Services
                    </div> */}
                    <h2 className="heading_text mb-0">
                        Vantagens da adoção da <mark>IA Generativa</mark>
                    </h2>
                    <h2 className="heading_text mb-0">
                        na plataforma de RH
                    </h2>
                    <p>A incorporação da IA generativa no Prolead oferece uma série de benefícios que transformam a maneira como as equipes de recursos humanos operam, aumentando a autonomia, a eficiência e a capacidade estratégica do setor. Aqui estão as vantagens gerais da adoção da IA generativa, que demonstram seu potencial de inovação e impacto.</p>
                </div>

                <div className="row">
                    {/* {Services.slice(5, 11).map((service, srv) => ( */}
                    {Services.slice(17, 23).map((service, srv) => (
                        <div className="col-lg-4" key={srv}>
                            <div className="service_block_2">
                                <div className="service_icon">
                                    <img src={service.sImg} alt="Techco - Service icon" />
                                </div>
                                <h3 className="service_title">
                                    {/* <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}> */}
                                    {service.title}
                                    {/* </Link> */}
                                </h3>
                                {/* <ul className="icon_list unordered_list_block">
                                    {service.features.map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-dot"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                    ))}
                                </ul> */}
                                <p>
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="btns_group" style={{ paddingBottom: '50px' }}>
                    <a target='blank' className="btn" href="https://proleadia.com.br/registration" style={{ width: '277px' }}>
                        <span className="btn_label" data-text="Experimente Agora">Experimente Agora</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </a>
                    <Link to="contactSection" smooth={true} duration={500}>
                        <a className="btn" href="" style={{ width: '277px' }}>
                            <span className="btn_label" data-text="Fale Conosco">Fale Conosco</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </a>
                    </Link>
                </div>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Techco Shape Angle" />
            </div>
            <div className="decoration_item shape_image_5">
                <img src={shape5} alt="Techco Shape Angle" />
            </div>
        </section>
    );
}

export default ServiceSection;